import ErrorIcon from '@mui/icons-material/Error';

function ErrorFallback() {
  return (
    <div className="flex flex-1 flex-col text-center items-center justify-center p-16 space-y-4">
      <ErrorIcon className="text-red-500 md:hidden" style={{ fontSize: '4rem' }} />
      <ErrorIcon className="text-red-500 hidden md:block" style={{ fontSize: '8rem' }} />
      <h1 className="text-2xl md:text-4xl font-bold">Something went wrong.</h1>
      <div>
        <span className="text-lg md:text-2xl">Please either </span>
        <span
          className="text-lg md:text-2xl text-blue-500 cursor-pointer underline"
          onClick={() => window.location.reload()}
        >
          refresh
        </span>
        <span className="text-lg md:text-2xl"> the page or try again later.</span>
      </div>

      <h2 className="text-lg md:text-2xl">If the issue persists, please contact us.</h2>
    </div>
  );
}

export default ErrorFallback;
