import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordSubmit, submitLogin } from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import _ from '@lodash';
import Typography from '@mui/material/Typography';
import { ATTRIBUTE_TYPE_SYSTEM, ATTRIBUTE_NAME_LOGIN_PAGE_MESSAGE } from '../services/constants';
import { getAttributes, selectAttributes } from '../main/support/store/attributesSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup.string().required('Please enter your password.'),
});

const defaultValues = {
  email: '',
  password: '',
};

function LoginForm(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const attributes = useSelector(selectAttributes);
  // const loginPageMessage =
  //   attributes && attributes.length
  //     ? attributes.find(
  //         (attr) =>
  //           attr.type === ATTRIBUTE_TYPE_SYSTEM && attr.name === ATTRIBUTE_NAME_LOGIN_PAGE_MESSAGE
  //       )?.value
  //     : null;

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (login.errors && login.errors.type) {
      setError(login.errors.type, {
        type: 'manual',
        message: login.errors.message,
      });
      setInProgress(false);
    }
  }, [login.errors, setError]);

  function onLoginSubmit(model) {
    setInProgress(true);
    dispatch(submitLogin(model));
  }

  useEffect(() => {
    dispatch(getAttributes(true));
  }, []);

  return (
    <div className="w-full">
      {/*{loginPageMessage && (*/}
      {/*  <div style={{ maxWidth: '250px' }}>*/}
      {/*    <span className="font-bold" style={{ fontSize: '8pt' }}>*/}
      {/*      {loginPageMessage}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
      <Typography variant="h6" className="mt-16 mb-24 font-semibold text-16 sm:text-20">
        Digital Assistant Services
      </Typography>

      <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onLoginSubmit)}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="text"
              label="Email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className="text-20" color="action">
                      email
                    </Icon>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              required
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="password"
              label="Password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputProps={{
                className: 'pr-2',
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                      <Icon className="text-20" color="action">
                        {showPassword ? 'visibility' : 'visibility_off'}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              required
            />
          )}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          disabled={_.isEmpty(dirtyFields) || !isValid || inProgress}
          value="cognito"
        >
          {inProgress ? 'Please wait...' : 'Login'}
        </Button>
      </form>
      <div className="flex flex-col items-center justify-center py-16">
        <a onClick={() => dispatch(forgotPasswordSubmit())} style={{ cursor: 'pointer' }}>
          Forgot Password
        </a>
      </div>
    </div>
  );
}

export default LoginForm;
