import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);
Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
});

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'billing-api',
        endpoint: process.env.REACT_APP_BILLING_API,
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
      {
        name: 'time-api',
        endpoint: `${process.env.REACT_APP_TIME_API_URL}?key=${process.env.REACT_APP_TIME_API_KEY}&format=json&by=zone&zone=UTC`,
      },
      {
        name: 'payment-gateway-api',
        endpoint: process.env.REACT_APP_PAYMENT_GATEWAY_API,
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
  Storage: {
    AWSS3: {
      useAccelerateEndpoint: true,
    },
  },
  Logging: {
    logGroupName: `flightdeck-frontend-${process.env.NODE_ENV}-ui-logs`,
    logStreamName: `flightdeck-frontend-ui-stream-${new Date()
      .toISOString()
      .split('T')[0]
      .replace(/[^a-zA-Z0-9]/g, '')}`,
  },
});
