import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';
import { accountsApi } from '../main/billing-admin/store/accountsApi';
import { mnosApi } from '../main/support/store/mnosApi';
import { paymentEntryApi } from '../main/billing-admin/store/paymentEntryApi';
import { correctionsApi } from '../main/billing-admin/store/correctionsApi';
import { contractsApi } from '../main/billing-admin/store/contractsApi';
import { codesApi } from '../main/billing-admin/store/codesApi';
import { pricesApi } from '../main/billing-admin/store/pricesApi';
import { journeysApi } from '../main/support/store/journeysApi';
import { templatesApi } from '../main/admin/store/templatesApi';
import { mnoConfigurationApi } from '../main/support/store/mnoConfigurationApi';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    fuse,
    i18n,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [mnosApi.reducerPath]: mnosApi.reducer,
    [paymentEntryApi.reducerPath]: paymentEntryApi.reducer,
    [correctionsApi.reducerPath]: correctionsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [codesApi.reducerPath]: codesApi.reducer,
    [pricesApi.reducerPath]: pricesApi.reducer,
    [journeysApi.reducerPath]: journeysApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [mnoConfigurationApi.reducerPath]: mnoConfigurationApi.reducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'auth/user/userLoggedOut') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
