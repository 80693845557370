import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import _ from '@lodash';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import format from 'date-fns/format';
import { closeNewCorrectionDialog } from '../store/correctionsSlice';

import FormMnoDropdown from '../../../shared-components/FormMnoDropdown';
import FormInputText from '../../../shared-components/FormInputText';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import FormAccountDropdown from '../../../shared-components/FormAccountDropdown';
import FormPlatformDropdown from '../../../shared-components/FormPlatformDropdown';
import { Constants } from '../../../services/constants';
import FormContractDropdown from '../../../shared-components/FormContractDropdown';
import { useSaveCorrectionMutation } from '../store/correctionsApi';

const defaultValues = {
  mno: '',
  dateEntered: '',
  date: '',
  user: '',
  note: '',
  platformId: '',
  billingAccountId: '',
  contractId: '',
  productCode: '',
  customerVisibleDescription: '',
  contentProviderId: '',
  subscriberAmount: '',
  subscriberAmountCurrency: '',
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  mno: yup.string().required('You must select an mno'),
  date: yup.date().required('You must enter a valid date'),
  note: yup.string(),
  platformId: yup.string().required('You must select billing platform'),
  billingAccountId: yup.string().required('You must select billing account'),
  contractId: yup.string().required('You must select a contract'),
  productCode: yup.string(),
  customerVisibleDescription: yup
    .string()
    .required('You must enter a description')
    .transform((_object, value) => {
      return value.replaceAll(',', '');
    }),
  contentProviderId: yup.lazy((value) =>
    value === '' ? yup.string() : yup.number().default(null)
  ),
  subscriberAmount: yup.lazy((value) =>
    value === '' ? yup.string().required('You must enter a valid amount') : yup.number().required()
  ),
  subscriberAmountCurrency: yup.string().required('You must select amount currency'),
});

const currencyList = process.env.REACT_APP_CURRENCY_LIST.split(',');

function CorrectionDialog(props) {
  const dispatch = useDispatch();
  const correctionDialog = useSelector(({ billing }) => billing.corrections.correctionDialog);
  const [saveCorrection] = useSaveCorrectionMutation();

  const user = useSelector(({ auth }) => auth.user);

  const { control, reset, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const selectedMno = watch('mno');

  const { isValid, dirtyFields, errors } = formState;

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'new'
     */
    reset({
      ...defaultValues,
      ...correctionDialog.data,
      user: user.data.email,
      dateEntered: new Date().toISOString(),
    });
  }, [correctionDialog.data, reset, user.data.email]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (correctionDialog.props.open) {
      initDialog();
    }
  }, [correctionDialog.props.open, initDialog]);

  /**
   * Close Dialog
   */
  function closeComposeDialog() {
    return dispatch(closeNewCorrectionDialog());
  }

  /**
   * Form Submit
   */
  function onSubmit(data) {
    data.productCode = data.productCode?.trim();
    data.customerVisibleDescription = data.customerVisibleDescription?.trim();
    saveCorrection({ ...data, date: format(data.date, Constants.DEFAULT_DATE_FORMAT) });
    closeComposeDialog();
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      {...correctionDialog.props}
      onClose={closeComposeDialog}
      fullWidth
      maxWidth="xs"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            New Correction
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24' }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormMnoDropdown
                name="mno"
                label="MNO"
                control={control}
                required
                allmno="true"
                noPermissionFiltering
              />
            </Grid>
            <Grid item>
              <FormInputText label="Date" name="date" control={control} required type="date" />
            </Grid>
            <Grid item>
              <FormInputText label="User" name="user" control={control} disabled />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="OnePIN Internal Note"
                    id="note"
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormPlatformDropdown
                name="platformId"
                label="Billing Platform"
                control={control}
                mno={selectedMno}
                required
              />
            </Grid>
            <Grid item>
              <FormAccountDropdown
                name="billingAccountId"
                label="Billing Account"
                control={control}
                mno={selectedMno}
                required
              />
            </Grid>
            <Grid item>
              <FormContractDropdown
                name="contractId"
                label="Contract"
                control={control}
                mno={selectedMno}
                required
              />
            </Grid>
            <Grid item>
              <FormInputText label="Product" name="productCode" control={control} type="text" />
            </Grid>
            <Grid item>
              <FormInputText
                label="Product Details"
                name="customerVisibleDescription"
                control={control}
                type="text"
                required
              />
            </Grid>
            <Grid item>
              <FormInputText
                label="Provider ID"
                name="contentProviderId"
                control={control}
                type="number"
              />
            </Grid>
            <Grid item>
              <FormInputText
                label="Amount"
                name="subscriberAmount"
                control={control}
                type="number"
                required
              />
            </Grid>
            <Grid item>
              <FormInputDropdown
                label="Amount Currency"
                name="subscriberAmountCurrency"
                control={control}
                required
                options={currencyList.map((val) => ({ value: val }))}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="justify-between p-4 pb-16">
          <div className="px-16">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CorrectionDialog;
