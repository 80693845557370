import { authRoles } from 'app/auth';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import applyErrorBoundary from 'app/store/errorBoundaryUtil';
import Orders from './orders/Orders';

const Order = lazy(() => import('./order/Order'));
const ExecutionStatuses = lazy(() => import('./executionStatuses/ExecutionStatuses'));

const routes = [
  {
    path: '/',
    exact: true,
    element: <Navigate to="/orders" />,
  },
  {
    path: '/orders/:orderId',
    element: <Order />,
  },
  {
    path: '/orders',
    element: <Orders />,
  },
  {
    path: '/executionStatus/:orderId/:executionDatetime',
    element: <ExecutionStatuses />,
  },
];

const OrdersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.userWithOrderPermission,
  routes: applyErrorBoundary(routes),
};

export default OrdersConfig;
