import withFlightdeckErrorBoundary from 'app/store/withFlightdeckErrorBoundary';

export default function applyErrorBoundary(routes) {
  return routes.map((route) => {
    const WrappedElement = withFlightdeckErrorBoundary(() => route.element);
    return {
      ...route,
      element: <WrappedElement />,
    };
  });
}