import { createApi } from '@reduxjs/toolkit/query/react';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from 'app/store/fuse/messageSlice';
import { graphqlBaseQuery } from '../../../../graphql/base-query';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';

const logger = new ComponentLogger('MnoConfigurationsApi');

export const mnoConfigurationApi = createApi({
  reducerPath: 'mnoConfigurationsApi',
  baseQuery: graphqlBaseQuery,
  tagTypes: ['MnoConfigurations'],
  endpoints: (builder) => ({
    getMnoConfigurations: builder.query({
      query: () => ({
        query: queries.listMnoConfigurations,
        variables: {},
      }),
      transformResponse: (response) => response.listMnoConfigurations.items,
      providesTags: ['MnoConfigurations'],
    }),
    updateMnoConfiguration: builder.mutation({
      async queryFn(mnoConfiguration, { dispatch }) {
        try {
          const { createdAt, updatedAt, ...input } = mnoConfiguration;
          const response = await graphqlBaseQuery({
            query: mutations.updateMnoConfiguration,
            variables: { input },
          });

          if (response.error) {
            logger.error('updateMnoConfiguration', {
              data: mnoConfiguration,
              error: response.error,
            });
            return { error: response.error };
          }

          return { data: response.data.updateMnoConfiguration };
        } catch (error) {
          logger.error('updateMnoConfiguration', { mnoConfiguration, error });
          return { error: 'Failed to save the mno configuration.' };
        }
      },
      invalidatesTags: ['MnoConfigurations'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: 'Mno Configuration has been updated successfully!',
              variant: 'success',
            })
          );
        } catch (error) {
          logger.error('updateMnoConfiguration', { error });
          dispatch(
            showMessage({
              message: 'An error occurred while updating the MNO configuration',
              variant: 'error',
            })
          );
        }
      },
    }),
    createMnoConfiguration: builder.mutation({
      async queryFn(mnoConfiguration, { dispatch }) {
        console.log(mnoConfiguration)
        try {
          const response = await graphqlBaseQuery({
            query: mutations.createMnoConfiguration,
            variables: { input: mnoConfiguration },
          });

          if (response.error) {
            logger.error('createMnoConfiguration', {
              data: mnoConfiguration,
              error: response.error,
            });
            return { error: response.error };
          }

          return {
            data: response.data.createMnoConfiguration,
          };
        } catch (error) {
          logger.error('createMnoConfiguration', { mnoConfiguration, error });
          return { error: 'Failed to save the mno configuration.' };
        }
      },
      invalidatesTags: ['MnoConfigurations'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: 'Mno Configuration has been created successfully!',
              variant: 'success',
            })
          );
        } catch (error) {
          logger.error('createMnoConfiguration', { error });
          dispatch(
            showMessage({
              message: 'An error occurred while creating the MNO configuration',
              variant: 'error',
            })
          );
        }
      },
    }),
  }),
});

export const { useGetMnoConfigurationsQuery, useCreateMnoConfigurationMutation, useUpdateMnoConfigurationMutation } =
  mnoConfigurationApi;
