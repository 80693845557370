import { createSlice } from '@reduxjs/toolkit';

export const mnoConfigurationSlice = createSlice({
  name: 'support/mno',
  initialState: {
    open: false,
    data: null,
    type: ''
  },
  reducers: {
    openMnoConfigurationDialog: (state, action) => {
      const { type, data } = action.payload;
      state.type = type;
      state.data = data;
      state.open = true;
    },
    closeMnoConfigurationDialog: (state, action) => {
      state.open = false;
      state.data = null;
      state.type = '';
    },
  },
});

export const { openMnoConfigurationDialog, closeMnoConfigurationDialog } =
  mnoConfigurationSlice.actions;

export default mnoConfigurationSlice.reducer;
