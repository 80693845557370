import { useDispatch } from 'react-redux';
import Root from 'app/shared-components/Root';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import MnoConfigurationDialog from './MnoConfigurationDialog';
import BillingSpinner from '../../billing-admin/BillingSpinner';
import { useGetMnoConfigurationsQuery } from '../store/mnoConfigurationApi';
import { openMnoConfigurationDialog } from '../store/mnoConfigurationSlice';

const columns = [
  { field: 'mno', headerName: 'Mno', flex: 1 },
  { field: 'cps', headerName: 'CPS', width: 200 },
  { field: 'lastActivityDurationInDays', headerName: 'Last Activity Duration in Days', width: 200 },
  { field: 'activationExclusionInDays', headerName: 'Activation Exclusion in Days', width: 200 },
  { field: 'transmitterTps', headerName: 'TPS', flex: 1 },
  { field: 'msisdnCountryCode', headerName: 'Country Code', flex: 1 },
  { field: 'billingPlatformId', headerName: 'Platform ID', flex: 1 },
  { field: 'transmitterStartHour', headerName: 'Transmitter Start Hour', flex: 1 },
  { field: 'transmitterEndHour', headerName: 'Transmitter End Hour', flex: 1 },
  { field: 'transmitterStartMinute', headerName: 'Transmitter Start Minute', flex: 1 },
  { field: 'transmitterEndMinute', headerName: 'Transmitter End Minute', flex: 1 },
  { field: 'cutOffDeliveryTime', headerName: 'Cut-off Delivery Time ', flex: 1 },
  {
    field: 'billingForRemindMeLaterEnabled',
    headerName: 'Billing for Remind Me Later Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'dndCriteriaEnabled',
    headerName: 'DND Criteria Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'optOutAllOptionEnabled',
    headerName: 'Opt out All Option Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  },

];

function MnoConfiguration() {
  const dispatch = useDispatch();
  const {
    data: mnoConfigurations = [],
    isLoading: mnoConfigurationsLoading,
    refetch,
    isFetching: mnoConfigurationsFetching,
  } = useGetMnoConfigurationsQuery();

  function onRowClick({ row }) {
    dispatch(openMnoConfigurationDialog({ type: 'edit', data: row }));
  }

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="settings"
            title="MNO Configuration"
            buttonName="New Configuration"
            onButtonClick={() => dispatch(openMnoConfigurationDialog({ type: 'new' }))}
          />
        }
        content={
          mnoConfigurationsFetching || mnoConfigurationsLoading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              getRowId={(row) => row.mno}
              rows={mnoConfigurations}
              onRowClick={onRowClick}
              columns={columns}
              autoPageSize
              pagination
              onRefresh={handleRefresh}
              disableColumnResize={false}
            />
          )
        }
        innerScroll
      />
      <MnoConfigurationDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('support', reducer)(MnoConfiguration);
