import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormInputText from 'app/shared-components/FormInputText';
import FormSwitch from 'app/shared-components/FormSwitch';
import _ from '../../../../@lodash';
import {
  useCreateMnoConfigurationMutation,
  useUpdateMnoConfigurationMutation,
} from '../store/mnoConfigurationApi';
import { closeMnoConfigurationDialog } from '../store/mnoConfigurationSlice';

const defaultValues = {
  mno: '',
  cps: '',
  lastActivityDurationInDays: '',
  activationExclusionInDays: '',
  transmitterTps: '',
  msisdnCountryCode: '',
  billingPlatformId: '',
  transmitterStartHour: '',
  transmitterEndHour: '',
  transmitterStartMinute: '',
  transmitterEndMinute: '',
  cutOffDeliveryTime: '',
  dndCriteriaEnabled: false,
  billingForRemindMeLaterEnabled: false,
  optOutAllOptionEnabled: false,
};

export default function MnoConfigurationDialog() {
  const dispatch = useDispatch();
  const { data, open, type } = useSelector(({ support }) => support.mnoConfigurations);
  const [createMnoConfiguration] = useCreateMnoConfigurationMutation();
  const [updateMnoConfiguration] = useUpdateMnoConfigurationMutation();

  const schema = yup.object().shape({
    mno: yup
      .string()
      .required('You must select MNO Name')
      .matches(
        /^[^_]+(_[^_]+)+$/,
        'MNO must include one or more underscores (_) separating valid parts, and it cannot start or end with an underscore.'
      ),
    cps: yup
      .string()
      .notRequired()
      .test(
        'is-valid-cps',
        'CPS must follow the format MediaX=2d:1,7d:3;BoosterX=2d:1,7d:3',
        (value) => !value || /^(\w+=\d+d:\d+(,\d+d:\d+)*)(;\w+=\d+d:\d+(,\d+d:\d+)*)*$/.test(value)
      ),
    lastActivityDurationInDays: yup
      .string()
      .notRequired()
      .test(
        'is-valid-last-activity-duration',
        'lastActivityDurationInDays must follow the format MediaX=90,BoosterX=90,BrandX=90',
        (value) => !value || /^\w+=\d+(,\w+=\d+)*$/.test(value)
      ),
    activationExclusionInDays: yup
      .string()
      .notRequired()
      .test(
        'is-valid-activation-exclusion',
        'activationExclusionInDays must follow the format MediaX=90,BoosterX=90,BrandX=90',
        (value) => !value || /^\w+=\d+(,\w+=\d+)*$/.test(value)
      ),

    transmitterTps: yup
      .string()
      .required('You must define transmitter TPS')
      .matches(/^\d+$/, 'Transmitter TPS must be a numeric value'),
    msisdnCountryCode: yup
      .string()
      .required('You must define MSISDN Country Code')
      .matches(/^\d+$/, 'Country Code must be a numeric value'),
    billingPlatformId: yup.string().required('You must define Billing Platform ID'),
    transmitterStartHour: yup
      .string()
      .required('You must define transmitter start hour')
      .matches(/^\d{1,2}$/, 'Transmitter Start Hour must be a numeric value between 0 and 23')
      .test('is-valid-minute', 'Transmitter Start Hour must be between 0 and 23', (value) => {
        if (!value) return true;
        const hour = parseInt(value, 10);
        return hour >= 0 && hour <= 23;
      }),
    transmitterEndHour: yup
      .string()
      .required('You must define transmitter end hour')
      .matches(/^\d{1,2}$/, 'Transmitter End Hour must be a numeric value between 0 and 23')
      .test('is-valid-minute', 'Transmitter End Hour must be between 0 and 23', (value) => {
        if (!value) return true;
        const hour = parseInt(value, 10);
        return hour >= 0 && hour <= 23;
      }),
    transmitterStartMinute: yup
      .string()
      .notRequired()
      .matches(/^\d{1,2}$/, 'Transmitter Start Minute must be a numeric value between 0 and 59')
      .test('is-valid-minute', 'Transmitter Start Minute must be between 0 and 59', (value) => {
        if (!value) return true;
        const minute = parseInt(value, 10);
        return minute >= 0 && minute <= 59;
      }),
    transmitterEndMinute: yup
      .string()
      .notRequired()
      .matches(/^\d{1,2}$/, 'Transmitter End Minute must be a numeric value between 0 and 59')
      .test('is-valid-minute', 'Transmitter End Minute must be between 0 and 59', (value) => {
        if (!value) return true;
        const minute = parseInt(value, 10);
        return minute >= 0 && minute <= 59;
      }),
    cutOffDeliveryTime: yup
      .string()
      .required('You must define cut off delivery time')
      .matches(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        'Cut Off Delivery Time must follow the format HH:MM and must be a valid hour.'
      ),
    billingForRemindMeLaterEnabled: yup
      .boolean()
      .required('Billing for Remind Me Later must be enabled or disabled')
      .typeError('Billing for Remind Me Later must be a boolean value'),
    dndCriteriaEnabled: yup
      .boolean()
      .required('DND Criterie must be enabled or disabled')
      .typeError('DND Criterie must be a boolean value'),
    optOutAllOptionEnabled: yup
      .boolean()
      .required('Opt-Out All Option must be enabled or disabled')
      .typeError('Opt-Out All Option must be a boolean value'),
  });

  const { control, reset, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const initDialog = useCallback(() => {
    reset({ ...defaultValues, ...data });
  }, [data, reset]);

  useEffect(() => {
    if (open) {
      initDialog();
    }
  }, [open, initDialog]);

  function onSubmit(payload) {
    console.log(payload, type);
    if (type === 'edit') {
      updateMnoConfiguration(payload);
    } else {
      createMnoConfiguration(payload);
    }
    onDialogClose();
  }
  function onDialogClose() {
    dispatch(closeMnoConfigurationDialog());
  }
  return (
    <Dialog open={open} fullWidth onClose={onDialogClose} onSubmit={handleSubmit(onSubmit)}>
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {_.capitalize(type)} MNO Configuration
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate className="flex flex-col md:overflow-hidden">
        <DialogContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="mno"
                placeholder="MNO"
                label="MNO"
                helperText={
                  errors.mno?.message ||
                  'MNO must include one or more underscores (_) separating valid parts, and it cannot start or end with an underscore.'
                }
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="cps"
                label="CPS"
                placeholder="CPS"
                helperText={errors.cps?.message || 'Format: MediaX=2d:1,7d:3;BoosterX=2d:1,7d:3'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="lastActivityDurationInDays"
                label="Last Activity Duration (Days)"
                placeholder="Last Activity Duration (Days)"
                helperText={
                  errors.lastActivityDurationInDays?.message ||
                  'Format: MediaX=90,BoosterX=90,BrandX=90'
                }
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="activationExclusionInDays"
                label="Activation Exclusion Duration (Days)"
                placeholder="Activation Exclusion Duration (Days)"
                helperText={
                  errors.activationExclusionInDays?.message ||
                  'Format: MediaX=90,BoosterX=90,BrandX=90'
                }
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="transmitterTps"
                label="Transmitter TPS"
                placeholder="Transmitter TPS"
                helperText={errors.transmitterTps?.message || 'Must be a numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="msisdnCountryCode"
                label="MSISDN Country Code"
                placeholder="MSISDN Country Code"
                helperText={errors.msisdnCountryCode?.message || 'Must be numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="billingPlatformId"
                label="Billing Platform ID"
                placeholder="Billing Platform ID"
                helperText={errors.billingPlatformId?.message}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="transmitterStartHour"
                label="Transmitter Start Hour"
                placeholder="Transmitter Start Hour"
                helperText={errors.transmitterStartHour?.message || 'Value between 0 and 23'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                label="Transmitter End Hour"
                name="transmitterEndHour"
                placeholder="Transmitter End Hour"
                helperText={errors.transmitterEndHour?.message || 'Value between 0 and 23'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="transmitterStartMinute"
                label="Transmitter Start Minute"
                placeholder="Transmitter Start Minute"
                helperText={errors.transmitterStartMinute?.message || 'Value between 0 and 59'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                label="Transmitter End Minute"
                name="transmitterEndMinute"
                placeholder="Transmitter End Minute"
                helperText={errors.transmitterEndMinute?.message || 'Value between 0 and 59'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                label="Cut Off Delivery Time"
                name="cutOffDeliveryTime"
                placeholder="Cut Off Delivery Time"
                helperText={errors.cutOffDeliveryTime?.message || 'Format: HH:MM'}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="Remind Me Later Enabled"
                name="billingForRemindMeLaterEnabled"
                placeholder="Remind Me Later"
                helperText={errors.billingForRemindMeLaterEnabled?.message}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="DND Criteria Enabled"
                name="dndCriteriaEnabled"
                placeholder="DND Criteria"
                helperText={errors.dndCriteriaEnabled?.message}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="Opt-Out All Option Enabled"
                name="optOutAllOptionEnabled"
                placeholder="Opt-Out All Option"
                helperText={errors.optOutAllOptionEnabled?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-start px-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            {type === 'edit' ? 'Save' : 'Add'}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(closeMnoConfigurationDialog())}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
