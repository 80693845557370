import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../main/error/ErrorFallback';

const withFlightdeckErrorBoundary = (WrappedComponent) => {
  return (props) => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
};

export default withFlightdeckErrorBoundary;
